import { createTheme } from '@mui/material/styles';
// import { computed } from 'mobx';
// Store import causes memory leak on back-end (refactoring store using signleton is required)
// import store from 'src/store';

// MaterialUi implementation example
// https://github.com/mui-org/material-ui/tree/master/examples/nextjs


const theme = createTheme({
	palette: {
		// type: 'light',
		primary: {
			main: '#0e5b89',
		},
		secondary: {
			main: '#ff7961',
		}
	},
});

// const dark = createTheme({
// 	palette: {
// 		type: 'dark',
// 		primary: {
// 			main: '#207dab',
// 		},
// 	},
// 	overrides: {
// 		MuiAppBar: {
// 			colorPrimary: {
// 				backgroundColor: '#053f5b',
// 			}
// 		},
// 	},
// });

// const theme = computed(() => {
// 	// return dark;
// 	return store.isDarkTheme ? dark : light;
// });

export default theme;
