import React from 'react';
import PropTypes from 'prop-types';
// NextJS
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next'
// Styles
import '../../styles/index.scss';
// MaterialUI
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter';
import theme from 'src/theme';
// Components
// Store import causes memory leak on back-end (refactoring store using signleton is required)
const MainLayout = dynamic(() => import('src/components/layout/MainLayout'), { ssr: false });
const Alerts = dynamic(() => import('src/components/Alerts'), { ssr: false });

function MyApp(props) {
	const { Component, pageProps } = props;
	return (
		<AppCacheProvider {...props}>
			<Head>
				<title>AxonOps</title>
				<meta name="viewport" content="initial-scale=1, width=device-width" />
			</Head>
			<ThemeProvider theme={theme}>
				{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
				<CssBaseline />

				<MainLayout>
					<Component {...pageProps} />
				</MainLayout>

				<Alerts /> 
			</ThemeProvider>
		</AppCacheProvider>
	)
}

MyApp.propTypes = {
	Component: PropTypes.elementType.isRequired,
	pageProps: PropTypes.object.isRequired,
};

export default appWithTranslation(MyApp);
